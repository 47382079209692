import * as React from "react"
import * as styles from "./footer.module.css"
import {Link} from "../../.cache/gatsby-browser-entry";

const Footer = ({siteTitle}) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerInner}>
                <nav className={styles.nav}>
                    <Link to="/">トップ</Link>
                    <Link to="/privacy">プライバシーポリシー</Link>
                </nav>

                <div>© {siteTitle}</div>
            </div>
        </footer>
    )
}

export default Footer
