import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import PropTypes from "prop-types";
import Header from "./header";
import TopCover from "./topCover";
import Footer from "./footer";
import * as styles from "./layout.module.css"

const Layout = ({children, isTop}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div className={styles.wrapper}>
            <Header siteTitle={data.site.siteMetadata?.title || ``}
                    isTop={isTop}/>
            {isTop ? <TopCover/> : <></>}

            <div className={isTop ? styles.content : styles.contentWithPaddingTop}>
                <main className={styles.main}>{children}</main>
            </div>

            <Footer siteTitle={data.site.siteMetadata.title}/>
        </div>
    )
}

Layout.defaultProps = {
    isTop: false
}

Layout.propTypes = {
    isTop: PropTypes.bool,
}

export default Layout
