import * as React from "react"
import * as styles from "./topCover.module.css"

const TopCover = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.image}/>
            <div className={styles.shade}/>
            <div className={styles.messages}>
                <p className={styles.mission}>手軽に、楽しく。</p>
                <p className={styles.subMessage}>老若男女、だれでも手軽に楽しめる脳トレアプリを提供している会社です。</p>
            </div>
        </div>
    )
}

export default TopCover
