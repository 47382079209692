import * as React from "react"
import {useEffect} from "react";
import {Link} from "gatsby"
import {Link as Scroll} from 'react-scroll';
import Logo from "../images/logo.png"
import * as styles from "./header.module.css"

const Header = ({siteTitle, isTop}) => {
    useEffect(() => {
        if (isTop) {
            const header = document.querySelector("header");
            window.addEventListener("scroll", function () {
                header.classList.toggle(styles.backgroundOff, window.scrollY < 30);
            });
        }
    }, [isTop])

    return (
        <header className={`${styles.header} ${isTop ? styles.backgroundOff : ''}`}>
            <div className={styles.wrapper}>
                <div className={styles.logoWrapper}>
                    <Link to="/">
                        <img className={styles.logo} src={Logo} alt={siteTitle}/>
                    </Link>
                </div>

                {isTop ?
                    <nav className={styles.nav}>
                        <Scroll to="apps" smooth={true} duration={300} offset={-70}>
                            <span>配信アプリ</span>
                        </Scroll>
                        <Scroll to="contact" smooth={true} duration={300} offset={-70}>
                            <span>コンタクト</span>
                        </Scroll>
                    </nav> :
                    <nav className={styles.nav}>
                        <Link to="/apps"><span>配信アプリ</span></Link>
                        <a href="mailto:nullhouse0@gmail.com"><span>コンタクト</span></a>
                    </nav>
                }
            </div>
        </header>
    )
}

export default Header
